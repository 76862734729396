let default_width = window.innerWidth;//アクセスした時の横幅を取得 スクロールバーを含めた値
let resize_width = window.innerWidth;
const break_point = 767;
jQuery(function() {
  let default_height = jQuery(document).innerHeight();//アクセスした時の高さを取得
  if (default_width >= break_point) {
    sliderMedia(4);
  } else {
    sliderMedia(2);
  }
  /*------------------------*/
  //リンクの範囲を広くする
  /*------------------------*/
  jQuery(".widelink").on('click',function() {
    let url = jQuery(this).find("a").attr("href");
    if (jQuery(this).find("a").attr("target") == '_blank') {
      window.open(url, '_blank');
      return false;
    } else {
      window.location = url;
      return false;
    }
  });

  jQuery(".widelink--sp").on('click',function(){
    if (default_width <= break_point) {
      window.location=jQuery(this).find("a").attr("href");
      return false;
    }
  });

  //サイドバー追従処理
  // barFix();
  jQuery(window).on('scroll',function() {
    //サイドバー追従処理
    barFix();
  });
  /*------------------------*/
  //ページトップ
  /*------------------------*/
  const topBtn = jQuery('.pageTop');
  topBtn.hide();
  let docH = jQuery(document).innerHeight(),
  winH = jQuery(window).height(),
  footeH = jQuery('.footer').outerHeight(),
  topBtnPos = docH - winH - footeH,
  flag = false;
  // スクロールが200に達したらボタン表示
  jQuery(window).scroll(function () {
    if (!flag) { //フラグがtrueでなければ取得
      docH = jQuery(document).height(),
      winH = jQuery(window).height()
      footeH = jQuery('.footer').outerHeight(),
      topBtnPos = docH - winH - footeH;
      flag = true;
    }
    if (jQuery(this).scrollTop() > 200 && !topBtn.hasClass("float") && !topBtn.hasClass("nonfloat")) {
      topBtn.fadeIn().addClass("float");
    } else if (jQuery(this).scrollTop() < topBtnPos && topBtn.hasClass("nonfloat")) {
      topBtn.removeClass("nonfloat").addClass("float");
    } else if (jQuery(this).scrollTop() > topBtnPos && topBtn.hasClass("float")) {
      topBtn.removeClass("float").addClass("nonfloat");
    } else if (jQuery(this).scrollTop() < 200) {
      topBtn.fadeOut().removeClass("float nonfloat");
    }
  });
  // スクロールしてトップ
  jQuery(".pageTop,.pageTop--article").on('click',function () {
    jQuery('body,html').animate({
        scrollTop: 0
    }, 500);
    return false;
  });

  //スムーススクロール（ページ内リンク）
  jQuery('a[href^="#"]').on("click", function () {
    const f = 600;
    e = jQuery(this).attr("href"),
    g = jQuery(e == "#" || e == "" ? "html" : e),
    h = jQuery(".header").height(),
    d = g.offset().top - h;
    jQuery("html, body").animate({
      scrollTop: d
    }, f, "swing");
    return false
  });
  jQuery('.js-scroll').on("click", function () {
    const f = 600;
    e = jQuery(this).attr("href"),
    g = jQuery(e == "#" || e == "" ? "html" : e),
    h = jQuery(".header").height(),
    d = g.offset().top - h;
    jQuery("html, body").animate({
      scrollTop: d
    }, f, "swing");
    return false
  });

  //tab
  jQuery('.tabMenu .tabMenu__item').on('click',function() {
    let i = jQuery(this).index();
    // クリック済みタブのデザインを設定したcssのクラスを一旦削除
    jQuery(this).siblings().removeClass('active');
    // クリックされたタブにクリック済みデザインを適用する
    jQuery(this).addClass('active');
    // コンテンツを一旦非表示にし、クリックされた順番のコンテンツのみを表示
    jQuery(this).parent().next(".tabItem").children(".tabList").hide().removeClass("show").eq(i).fadeIn(300).addClass("show");
  });

  //accordion
  jQuery(".js-accordion .js-accordion__btn").on('click',function() {
    jQuery(this).toggleClass("open");
    jQuery(this).next(".js-accordion__item").slideToggle();
  });

  //pulldown
  jQuery(".js-pulldown .js-pullDownList").on('click',function() {
    jQuery(this).toggleClass("is-show").find(".js-pullDownItem").not(":first").slideToggle();
  });

  //目次
  // if (jQuery(".tocBox__inner").children(".tocList").children(".tocList__item").length >= 4) {
  //   let flg = 'closed'; //デフォルトの設定：表示='opened'、非表示='closed'
  //   　jQuery('.tocBox__inner').append('<p class="tocMore">目次を開く</p>'); //デフォルトの開閉リンク文字列
  //   jQuery('.tocMore').on('click', function() {
  //     jQuery(this).parents('.tocBox').toggleClass('open');
  //     if(flg == 'closed'){
  //       jQuery(this).text('目次を閉じる'); //表示状態のリンク文字列
  //       flg = 'opened';
  //     }else{
  //       jQuery(this).text('目次を開く'); //非表示の状態のリンク文字列
  //       flg = 'closed';
  //     }
  //   });
  // } else {
  //   jQuery('.tocMore').on('click', function() {
  //     jQuery('.tocBox').addClass('open');
  //   });
  // }

  //追従バナー
  const fixB = jQuery(".fixBnr");
  if (fixB.length) {
    jQuery(window).on('scroll',function () {
      let doch = jQuery(document).innerHeight(), //ページ全体の高さ
      winh = jQuery(window).innerHeight(), //ウィンドウの高さ
      bottom = doch - winh, //ページ全体の高さ - ウィンドウの高さ = ページの最下部位置
      footh = jQuery(".footer").innerHeight();
      if (jQuery(this).scrollTop() > 100 && jQuery(this).scrollTop() < bottom - footh) {
        fixB.addClass("show");
      } else {
        fixB.removeClass("show");
      }
    });
  }

  /*----------------------------------------------------*/
  /* [PC][SP]UseragentでJSを切り替え
  /*----------------------------------------------------*/
  let getDevice = (function() {
  let ua = navigator.userAgent;
    if (!(ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0)) {
      /*-------------------------------------*/
      /* PCの時
      /*-------------------------------------*/
      jQuery(".widelink").hover(
        function(e) {
            jQuery(this).addClass("hover");
        },
        function(e) {
            jQuery(this).removeClass("hover");
        }
      );
    }
  })();

  /*------------------------*/
  //バーガーメニュー表示・非表示
  /*------------------------*/
  const burgerBtn = jQuery('.burger');
  let headerHeight = jQuery('.header').innerHeight();
  let headerInnerHeight = jQuery('.kv').height();
  // スクロールがヘッダーの高さに達したらボタン表示
  jQuery(window).scroll(function () {
    if (jQuery(this).scrollTop() > headerInnerHeight + 200) {
      jQuery(".header").addClass("fixedNav");
    } else if (jQuery(this).scrollTop() < headerInnerHeight) {
      if (jQuery(".header").hasClass("fixedNav")) {
        jQuery(".header").removeClass("fixedNav");
      }
    } else {
      if (jQuery(".header").hasClass("fixedNav")) {
        jQuery(".header").removeClass("fixedNav");
      }
    }
  });
  //バーガーボタンクリック
  burgerBtn.on('click',function() {
    default_width = window.innerWidth;//クリックされた時の数値をdefault_widthに設定
    if (default_width <= 930) {
      burgerBtn.toggleClass("is-open");
      if (jQuery(".gNav").hasClass("show")) {
        jQuery(".gNav").removeClass("show").addClass("hide");
      } else if (jQuery(".gNav").hasClass("hide")) {
        jQuery(".gNav").removeClass("hide").addClass("show");
      } else {
        jQuery(".gNav").addClass("show");
      }
      if (jQuery(this).hasClass("is-open")) { //バーガーメニューが開かれた状態か
        jQuery(".headerNav__cover").removeClass("hide").addClass("show");
      } else {
        jQuery(".headerNav__cover").addClass("hide").removeClass("show");
      }
    }
  });
  //カバーをクリック
  jQuery(".headerNav__cover,.gNav a").on('click',function() {
    if (default_width <= 930) {
      jQuery(".gNav").removeClass("show").addClass("hide");
      burgerBtn.removeClass("is-open");
      jQuery(".headerNav__cover").addClass("hide").removeClass("show");
    }
  });
  
  /*------------------------*/
  //リサイズ時の処理（リサイズした瞬間に走る）
  /*------------------------*/
  jQuery(window).on('resize',function() {
    if (default_width == window.innerWidth){//アクセスした時と、リサイズした時のウィンドウ幅が同じかどうかを判定
      return;//アクセスした時と、リサイズした時のウィンドウ幅が同じだった時に処理を抜ける
    } else {
      default_width = window.innerWidth;//リサイズ処理がされた場合、default_widthの数値を更新
      resize_width = window.innerWidth;//リサイズが完了したらウィンドウサイズの更新
      if (resize_width <= break_point) {
        /*------------------------*/
        //スマホ処理（リサイズ時）
        /*------------------------*/
        //バーガーメニューを開いたままリサイズされた時の処理
        jQuery(".gNav").removeClass("show hide");
        burgerBtn.removeClass("is-open");
        jQuery(".headerNav__cover").addClass("hide").removeClass("show");
      } else {
        /*------------------------*/
        //PC処理（リサイズ時）
        /*------------------------*/
        jQuery(".gNav").removeClass("show hide");
      }
    }
  });

  /*------------------------*/
  //リサイズ完了時点処理（完了するまで処理されない）
  /*------------------------*/
  let timer = false;
  jQuery(window).on('resize',function() {
    if (timer !== false) {
        clearTimeout(timer);
    }
    timer = setTimeout(function() {
      resize_width = window.innerWidth;//リサイズが完了したらウィンドウサイズの更新
      if (resize_width <= break_point) {
        /*------------------------*/
        //スマホ処理（リサイズ完了時）
        /*------------------------*/
        // jQueryheader.removeClass(scrollClass);
        sliderMedia(2);
      } else {
        /*------------------------*/
        //PC処理（リサイズ完了時）
        /*------------------------*/
        sliderMedia(4);
      }
      //サイドバー追従処理
      barFix();

      docH = jQuery(document).height(),
      winH = jQuery(window).height()
      footeH = jQuery('.footer').outerHeight(),
      topBtnPos = docH - winH - footeH;
    }, 200);
  });
  if ($(".kv__slider").length) {
    const swiper = new Swiper('.kv__slider', {
      loop: true,//ループ
      effect: 'fade',//フェードエフェクト適用
      speed: 2000,//スライドスピード
      slidesPerView: 1,
      allowTouchMove: false,
      autoplay: {//自動再生
        delay: 6000,
        disableOnInteraction: false,
        stopOnLastSlide: false
      },
    })
  }
});


/*------------------------*/
//関数
/*------------------------*/
let barFix = function() {
  //該当のセレクタなどを代入
  const mainArea = jQuery(".layout--2col__cont"), //メインコンテンツ
  sideWrap = jQuery(".sideNav"), //サイドバーの外枠
  sideArea = jQuery(".sideNav__inner"), //サイドバー
  wd = jQuery(window); //ウィンドウ自体

  //値の初期化
  sideArea.css({"position": "", "bottom": "", "top": ""});
  sideWrap.css({"height": "","position": ""});
  sideArea.css({"position": "", "bottom": "", "top": ""});

  //メインとサイドの高さを比べる 
  let mainH = mainArea.innerHeight(),
  sideH = sideWrap.innerHeight(),
  now_width = window.innerWidth,
  isSmartPhone = false;
  if(now_width <= 1023) {
    // 指定幅より狭いのでスマホとして判断する
    isSmartPhone = true;
  }

  if(sideH < mainH && isSmartPhone == false) { //メインの方が高ければ色々処理する
    //サイドバーの外枠をメインと同じ高さにしてrelaltiveに（#sideをポジションで上や下に固定するため）
    sideWrap.css({"height": mainH,"position": "relative"});
    //サイドバーがウィンドウよりいくらはみ出してるか
    let sideOver = wd.height()-sideArea.height();
    //固定を開始する位置 = サイドバーの座標＋はみ出す距離
    let starPoint = sideArea.offset().top + (-sideOver);
    //固定を解除する位置 = メインコンテンツの終点
    let breakPoint = sideArea.offset().top + mainH;

    if(wd.height() < sideArea.height()){ //サイドメニューが画面より大きい場合
      if(starPoint < wd.scrollTop() && wd.scrollTop() + wd.height() < breakPoint){ //固定範囲内
        sideArea.css({"position": "fixed", "bottom": "0"}); 
      }else if(wd.scrollTop() + wd.height() >= breakPoint){ //固定解除位置を超えた時
        sideArea.css({"position": "absolute", "bottom": "0"});
      } else { //その他、上に戻った時
        sideArea.css("position", "static");
      }
    } else { //サイドメニューが画面より小さい場合
    let sideBtm = wd.scrollTop() + sideArea.height(); //サイドメニューの終点
    if(mainArea.offset().top < wd.scrollTop() && sideBtm < breakPoint){ //固定範囲内
      sideArea.css({"position": "fixed", "top": "0"});
    }else if(sideBtm >= breakPoint){ //固定解除位置を超えた時
      //サイドバー固定場所（bottom指定すると不具合が出るのでtopからの固定位置を算出する）
      let fixedSide = mainH - sideH;
      sideArea.css({"position": "absolute", "top": fixedSide});
      } else {
        sideArea.css("position", "static");
      }
    }
  }
  if (isSmartPhone == true) {
    sideWrap.css({"height": "auto","position": "static"});
  }
}

/*トップページ　スライダー*/
let sliderMedia = function(pieces) {
  jQuery(function() {
    const jQueryslider = jQuery('.slider--media');
    if (jQueryslider.hasClass("slick-slider")) {//slickが設置されていたら一旦解除
      jQueryslider.slick('unslick');
    }
    if ($('.sliderWrap').length >= pieces) {//slickを設置するかの個数をpiecesで管理
      //slider設置
      jQueryslider.slick({
        autoplay: true,
        autoplaySpeed: 5000,
        accessibility: false,
        dots: true,
        slidesToShow: 3,
        arrows: true,
        centerMode: true,
        centerPadding: '0%',
        responsive: [
          {
            breakpoint: 767,//ブレイクポイントを指定
            settings: {
              slidesToShow: 1,
              draggable: true
            }
          }
        ]
      });
    }
  });
}
